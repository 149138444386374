const ACCESS_TOKEN = "EAADXOwz5vVUBAGdZAwp5BbiHuIW5CvXeYlhgcgwM3iWxD0bgyeqzyUSlwZBC8djb9vojO0Qw1tdKZCiub2ZCAtXo7SXLuP1baapTabbeD7ZBT7VvfgmOeAkjCyZCGoDaxZAbbD4qvnQst7qm66eeeRB44aWcfm7EVkOxCAniZBhJalID0XEeURp9"
const BASE = "https://graph.facebook.com/v6.0/"

export default {
  feed: async (accountId, options = {}) => {
    return await fetch(BASE + `/${accountId}/media?` + new URLSearchParams({
      access_token: ACCESS_TOKEN,
      fields: "media_url,media_type,thumbnail_url,permalink",
      ...options
    }))
  }
}