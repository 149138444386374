import React from "react"

import Icon from "../../atoms/icon"
import SvgIcon from "../../atoms/svg-icon"

import ContactInfo from "../../molecules/contact-info"

import Api from "../../lib/api"

import {
  FooterContainer,
  StyledLogo,
  MailingListForm,
  BoldText,
  Input,
  SignUpButton,
  Fields,
  Contacts,
  SocialNetworks,
  SocialNetworkLink,
  Credits,
  CreditBlock,
  ThankYouText
} from "./styles"

export default class Footer extends React.PureComponent {
  state = {
    registered: false,
    loading: false
  }

  signUpEmail = async (e) => {
    e.preventDefault();
    this.setState({loading: true})
    const email = document.getElementById("email").value
    
    await Api.emailSignup(email)

    this.setState({loading: false, registered: true})
  }

  render() {
    const {registered, loading} = this.state

    return (
      <FooterContainer>
        <StyledLogo height={162}/>
        <MailingListForm onSubmit={this.signUpEmail}>
          <BoldText>Join our mailing list!</BoldText>
            {registered ? (
              <ThankYouText>
                ¡Gracias, bienvenido a la familia!
              </ThankYouText>
            ) : (
              <Fields>
                <Input
                  id="email"
                  disabled={loading}
                  placeholder="Enter your email address"
                  type="email"
                  name="email"
                />
                <input name="form-name" value="Mailing List" type="hidden" />
                <SignUpButton
                  active={loading}
                  type="submit"
                  variant="button"
                  value="Sign Up"
                >
                  Sign Up
                </SignUpButton>
              </Fields>
            )}
        </MailingListForm>
        <Contacts>
          <ContactInfo
            role="Management"
            name="Emanuel Gamarra"
            email="management@loscrankers.com"
          />
          <ContactInfo
            role="Press"
            name="Jairo Macías"
            email="press@loscrankers.com"
          />
          <ContactInfo
            role="Info"
            name="Hans Gamarra"
            email="info@loscrankers.com"
          />
        </Contacts>
        <SocialNetworks>
          <SocialNetworkLink href="https://www.facebook.com/LosCrankers/" target="new">
            <Icon name="Facebook"/>
          </SocialNetworkLink>
          <SocialNetworkLink href="https://www.instagram.com/loscrankers/" target="new">
            <Icon name="Instagram"/>
          </SocialNetworkLink>
          <SocialNetworkLink href="https://twitter.com/loscrankers" target="new">
            <Icon name="Twitter"/>
          </SocialNetworkLink>
          <SocialNetworkLink href="https://www.youtube.com/user/TheCrankersTV" target="new">
            <Icon name="Youtube"/>
          </SocialNetworkLink>
          <SocialNetworkLink href="https://www.linkedin.com/company/los-crankers" target="new">
            <Icon name="Linkedin"/>
          </SocialNetworkLink>
          <SocialNetworkLink href="https://music.apple.com/co/artist/los-crankers/1396344326?l=en" target="new">
            <Icon name="Apple"/>
          </SocialNetworkLink>
          <SocialNetworkLink href="https://www.deezer.com/en/artist/14995879" target="new">
            <SvgIcon src="/images/brands/deezer.svg" />
          </SocialNetworkLink>
          <SocialNetworkLink href="https://open.spotify.com/artist/6BV6125nwCfygQI36SXv4l?si=gcqhaC4USiSWYwVmg1WVGw" target="new">
            <Icon name="Spotify"/>
          </SocialNetworkLink>
        </SocialNetworks>
        <Credits>
          <CreditBlock>
            Los Crankers ©
          </CreditBlock>
          <CreditBlock>
            All Rights Reserved {new Date().getFullYear()}
          </CreditBlock>
        </Credits>
      </FooterContainer>
    )
  }
}